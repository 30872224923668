import { createBrowserRouter, Outlet } from "react-router-dom";
import Main from "../pages/app/Main";
import { ttRoutes } from "./hooks/useTTRouter";
import Login from "../pages/app/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import App from "../App";
import { PartyPage } from "src/pages/app/Party/PartyPage";
import StaticPage from "../pages/static/(main)/page";
import { PlatformDecide } from "../pages";
import UnProtectedRoute from "./components/UnProtectedRoute";
import { LoadMoneyPage } from "../pages/app/LoadMoneyPage";
import { LogoutPage } from "../pages/app/LogoutPage";
import { AccountPage } from "src/pages/app/User/AccountPage";
import { ProfilePage } from "src/pages/app/User/ProfilePage";

import { CreatePartyPage } from "src/pages/app/Party/CreatePartyPage";
import { AccountHistoryPage } from "src/pages/app/User/PartiesHistoryPage";
import { ChatPage } from "src/pages/app/Party/ChatPage";
import { GamePage } from "src/pages/app/Party/GamePage";
import LeaderboardPage from "src/pages/app/Party/LeaderboardPage";
import Settings from "src/pages/Settings/SettingsPage";
import { FollowingPage } from "src/pages/app/Profile/FollowingPage";
import { FollowersPage } from "src/pages/app/Profile/FollowersPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PlatformDecide />,
  },

  {
    path: "/static",
    element: <StaticPage />,
  },

  {
    path: ttRoutes.login(),
    element: (
      <UnProtectedRoute>
        <Login />
      </UnProtectedRoute>
    ),
  },

  {
    path: ttRoutes.partyChat(),
    element: (
      <ProtectedRoute>
        <ChatPage />
      </ProtectedRoute>
    ),
  },

  {
    path: ttRoutes.game(),
    element: (
      <ProtectedRoute>
        <GamePage />
      </ProtectedRoute>
    ),
  },

  {
    path: "/app",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    children: [
      {
        path: ttRoutes.logout(),
        element: <LogoutPage />,
      },

      {
        path: ttRoutes.loadMoney(),
        element: <LoadMoneyPage />,
      },

      {
        path: ttRoutes.home(),
        element: <Main />,
      },

      {
        path: ttRoutes.paymentReturn(),
        element: <Main />,
      },

      {
        path: ttRoutes.party(),
        element: <PartyPage />,
      },

      {
        path: ttRoutes.myProfile(),
        element: <AccountPage />,
      },

      {
        path: ttRoutes.userProfile(),
        element: <ProfilePage />,
      },

      {
        path: ttRoutes.leaderboard(),
        element: <LeaderboardPage />,
      },

      {
        path: ttRoutes.followers(),
        element: <FollowersPage />,
      },

      {
        path: ttRoutes.createParty(),
        element: <CreatePartyPage />,
      },

      {
        path: ttRoutes.createPartyWithGame(),
        element: <CreatePartyPage />,
      },

      {
        path: ttRoutes.userHistory(),
        element: <AccountHistoryPage />,
      },

      {
        path: ttRoutes.following(),
        element: <FollowingPage />,
      },
      {
        path: ttRoutes.settings(),
        element: <Settings />,
      },
    ],
  },
]);

export default router;
