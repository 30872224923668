import { JoinPartyByCodeCard } from "src/components/JoinPartyCard";
import useTTRouter from "../../router/hooks/useTTRouter";
import { Game, Party } from "../../types";
import { useBubblesBackground } from "../../providers/layoutProvider";
import { useParties } from "../../providers/partiesProvider";
import { ClaimRewardsCard } from "../../components/RewardCard";
import PullToRefresh from "react-simple-pull-to-refresh";
import { EditablePartiesList } from "src/components/EditablePartiesList";
import { FixedPartiesHorizontalList } from "src/components/FixedPartiesHorizontalList";
import { useAccount } from "src/providers/accountProvider";
import { VerticalGamesList } from "src/components/VerticalGamesList";
import useGames from "src/hooks/useGames";
import { useEffect } from "react";
import { MainGamesList } from "./MainGamesList";

export default function Main() {
  useBubblesBackground();
  const { privateParties, isPartiesLoading, refreshParties } = useParties();
  const { navigateToParty, navigateToPartyCreateWithGame } = useTTRouter();
  const { refreshBalances } = useAccount();
  const { data: games } = useGames();

  useEffect(() => {
    handleRefresh();
  }, []);

  function handlePartySelection(party: Party) {
    navigateToParty(party.id);
  }

  function handleGameSelection(game: Game) {
    navigateToPartyCreateWithGame(game.id);
  }

  async function handleRefresh() {
    refreshParties();
    refreshBalances();
  }

  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <div className="flex flex-col gap-y-2 w-full md:w-2/3 lg:w-1/2 h-fit pb-60">
        <div className="flex flex-col px-2 gap-y-2">
          <JoinPartyByCodeCard />
        </div>

        <EditablePartiesList
          title={"Private Parties 🎉"}
          onPartyClick={(party: Party) => handlePartySelection(party)}
          parties={privateParties}
          isLoading={isPartiesLoading}
        />

        <MainGamesList
          options={games!}
          onChange={(game: Game) => handleGameSelection(game)}
        />
      </div>
    </PullToRefresh>
  );
}
