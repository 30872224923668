import { Game } from "../../types";
import { Get } from "../fetch.wrapper";
import { routes } from "../routes";

export default async function GetGames(): Promise<Game[]> {
  const data = await Get(routes.games());

  return data.map(
    (d: any) =>
      ({
        id: d.id,
        name: d.name,
        description: d.description,
        gameplayPreview: d.preview_url,
        gamePreview: d.pre_game_url,
        icon: d.icon_url,
      } as Game)
  );
}
