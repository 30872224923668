import { useState } from "react";
import { Game } from "src/types";
import { GradientBorder } from "./ui/GradientContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export function VerticalGamesList({
  value,
  options,
  onChange,
}: Readonly<{
  value: Game | null;
  options: Game[];
  onChange: (game: Game) => void;
}>) {
  const [query, setQuery] = useState<string | null>(null);

  function isSelected(current: Game | null, game: Game) {
    return current !== null && current.id === game.id;
  }

  const filteredOptions = query
    ? options.filter((option) =>
        option.name.toLowerCase().includes(query.toLowerCase())
      )
    : options;

  return (
    <div className="flex flex-col items-center h-screen w-screen">
      <div className="flex flex-col items-center h-full gap-y-4 px-2 w-full">
        <div className="relative w-full">
          <input
            className="w-full p-3 pl-12 rounded-2xl border border-gray-500 bg-zinc-900 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            placeholder="Search game"
            onChange={(e) => setQuery(e.target.value)}
            value={query || ""}
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute top-1/2 left-4 ml-2 transform -translate-y-1/2 text-gray-400"
          />
        </div>

        <div className="flex flex-wrap w-full justify-center gap-x-2 gap-y-2">
          {filteredOptions.map((option) => (
            <div
              className="shadow-md h-40 w-40 my-2 relative cursor-pointer"
              onClick={() => onChange(option)}
              key={option.id}
            >
              {isSelected(value, option) ? (
                <GradientBorder>
                  <img
                    className="rounded-2xl object-cover"
                    src={option.icon}
                    alt={option.name}
                  />
                </GradientBorder>
              ) : (
                <img
                  className="rounded-2xl object-cover"
                  src={option.icon}
                  alt={option.name}
                />
              )}
              <p className="font-bold text-white text-center">{option.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
