import React, { ReactNode } from "react";

interface SheenProps {
  children: ReactNode;
  className?: string;
  speed?: number; // seconds
}

const Sheen: React.FC<SheenProps> = ({ children, speed = 1.5 }) => {
  return (
    <div className={`relative overflow-hidden`} style={{ "--sheen-speed": `${speed}s` } as React.CSSProperties}>
      {children}
      <div className="absolute inset-0 before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-white/60 before:to-transparent before:w-[150%] before:h-full before:translate-x-[-100%] before:animate-sheen"></div>
    </div>
  );
};

export default Sheen;
