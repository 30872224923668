import { useEffect, useRef, useState } from "react";
import { GradientBorder } from "src/components/ui/GradientContainer";
import { Game } from "src/types";

export function MainGamesList({
  options,
  onChange,
}: Readonly<{
  options: Game[];
  onChange: (game: Game) => void;
}>) {
  return (
    <div className="px-2">
      <div className="relative w-full h-full">
        <div className="absolute w-full h-full backdrop-blur-xl rounded-xl"></div>
        <div className="flex flex-row relative items-center justify-between bg-gradient-to-br to-white/10 via-[#34414d]/10 from-[#34414d]/10 border-hairline border-[#34414d]/30 rounded-xl py-2 px-4">
          <p className="text-white font-semibold text-xl">Pick a game! 🎮</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 p-2">
        {options.map((src) => (
          <div className="relative w-full h-full"
            onClick={() => onChange(src)}
            key={src.id}>
            <img
              src={src.gameplayPreview}
              className="object-cover rounded-lg shadow-lg"
            />
            <div className="absolute rounded-lg bottom-0 left-0 w-full h-20 mask-image-bottom-blur backdrop-blur-lg"></div>
            <div className="absolute bottom-0 left-0 px-2 py-3 text-white font-semibold text-md">{src.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};